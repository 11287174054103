import request from '@/utils/request'

/**
 * 登录
 */
export const userLogin = (data) => {
  return request({
    url: '/face/user/login',
    method: 'post',
    data,
  })
}


/**
 * 发送验证码
 */
export const sendSmsCode = (params) => {
  return request({
    url: '/face/sms/send',
    method: 'get',
    params,
  })
}
