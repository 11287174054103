<template>
  <el-dialog
    :visible.sync="dialogVisible"
    append-to-body
    :modal-append-to-body="true"
    class="login__model"
    width="361px"
    :before-close="closeModal"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    lock-scroll
  >
    <div v-show="showType === 1" class="el__outerLayer">
      <div class="el__he">
        <img class="el__hard" src="@/assets/app_logo.png" alt="" />
        <img class="el__breed" src="@/image/materil__breed.png" alt="" />
      </div>
      <div class="cellPhone__number">
        <input
          v-model="phone"
          class="cellPhone__input"
          type=""
          placeholder="请输入手机号"
          @focus="focusPhone = true"
          @blur="focusPhone = false"
          maxlength="11"
        />
        <img v-if="phone.length" class="cellPhone__vector" @click="phone = ''" src="@/image/material__vector.png" alt="" />
      </div>
      <div class="cellPhone__number" style="margin-bottom: 30px">
        <input v-model="code" class="cellPhone__input" type="" placeholder="请输入手机验证码" maxlength="6" />
        <span class="send__code btnHover" @click="getCode">{{ codeTimeDown ? `${codeTimeDown}s` : '获取验证码' }}</span>
      </div>
      <div class="logIn__butt btnHover hidden-xs-only" @click="submitLogin">{{ isBindWx ? '绑定手机号' : '登录' }}</div>
      <div class="logIn__butt small__btn btnHover hidden-sm-and-up" @click="submitLogin">{{ isBindWx ? '绑定手机号' : '登录' }}</div>
      <!-- <div v-if="!isBindWx" class="weChat__board btnHover" @click="getToggleView(2)">
        <img class="w-6 h-6" src="@/image/material__weChat.png" alt="" />
        <span class="weChat__Uname">通过微信登录</span>
      </div> -->
    </div>

    <div v-show="showType === 2" class="microScan__Side">
      <img @click="getToggleView(1)" class="microScan__return" src="@/image/material__left.png" alt="" />
      <div class="el__he">
        <img class="el__hard" src="@/assets/app_logo.png" alt="" />
        <img class="el__breed" src="@/image/materil__breed.png" alt="" />
      </div>
      <div class="qrCode__picture" id="login_container"></div>
      <div class="open__beat">打开 <img class="open__weCat" src="@/image/material__weChat.png" alt="" /> 微信扫一扫登录</div>
    </div>

    <div class="read__part pb-9">
      <!-- <span class="read__icon"></span> -->
      <el-checkbox v-model="readAgree"></el-checkbox>
      <div class="read__agree hidden-xs-only">
        已阅读并同意
        <div class="btnHover flex items-center" @click="gotoPage(1)">
          <img class="el__bre" src="@/image/materil__breed.png" alt="" />
          用户协议
        </div>
        和
        <div class="btnHover flex items-center" @click="gotoPage(2)">
          <img class="el__bre btnHover" src="@/image/materil__breed.png" alt="" />
          隐私政策
        </div>
      </div>
      <div class="read__agree hidden-sm-and-up">
        同意
        <div class="btnHover flex items-center" @click="gotoPage(1)">
          <img class="el__bre" src="@/image/materil__breed.png" alt="" />
          用户协议
        </div>
        和
        <div class="btnHover flex items-center" @click="gotoPage(2)">
          <img class="el__bre btnHover" src="@/image/materil__breed.png" alt="" />
          隐私政策
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { sendSmsCode, userLogin } from '@/api/v2'

var codeTimer = null

export default {
  data() {
    return {
      dialogVisible: false,
      showType: 1,
      phone: '',
      code: '',
      codeTimeDown: 0,
      focusPhone: false,
      readAgree: !!localStorage.getItem('bread_front_readAgree') // 协议
    }
  },

  computed: {
    ...mapGetters(['showLoginModal', 'loginModalInfo']),

    showClear() {
      return this.focusPhone && this.phone
    },

    isBindWx() {
      return this.loginModalInfo.needRegPhoneNumber && this.loginModalInfo.openid
    }
  },

  watch: {
    showLoginModal: {
      immediate: true,
      handler: function (v) {
        this.dialogVisible = v
        this.code = ''
        this.phone = ''
        if (v) {
          this.getToggleView(1)
        }
      }
    }
  },

  mounted() {},

  methods: {
    getToggleView(e) {
      this.showType = e
      this.phone = ''
      this.code = ''
      if (codeTimer) clearTimeout(codeTimer)
      this.codeTimeDown = 0

      if (e === 2) {
        setTimeout(() => {
          new window.WxLogin({
            self_redirect: false,
            id: 'login_container',
            appid: 'wx429312c9e32d0488',
            scope: 'snsapi_login',
            redirect_uri: 'https://stage.breed.video/oauth/callback/wechat',
            state: 'BV_STATE567',
            style: '',
            stylelite: 1,
            href: ''
          })
        }, 10)
      }
    },

    closeModal() {
      this.getToggleView(1)
      this.$store.commit('SET_KEY', {
        key: 'loginModalInfo',
        value: {
          needRegPhoneNumber: false,
          openid: ''
        }
      })
      this.$store.commit('SET_KEY', { key: 'showLoginModal', value: false })
    },

    /**
     * 获取验证码
     */
    async getCode() {
      if (!this.readAgree) return this.$message.error('请勾选阅读协议')
      if (codeTimer) clearTimeout(codeTimer)
      if (!this.codeTimeDown) {
        try {
          if (!this.phone.length || !/^1[3-9]\d{9}$/.test(this.phone)) {
            return this.$message.error('请输入正确的手机号')
          }
          // await this.$axios.post('/v1/users/login/sms/send', { phone: `+86${this.phone}` })
          sendSmsCode({ phone: `${this.phone}` })
          this.$message.success('验证码发送成功')
        } catch (error) {
          this.$message.error(error || '服务异常，请重试')
          return false
        }
        this.codeTimeDown = 60
      }
      codeTimer = setTimeout(() => {
        this.codeTimeDown -= 1

        if (this.codeTimeDown < 1) {
          this.codeTimeDown = 0
          clearTimeout(codeTimer)
        } else {
          this.getCode()
        }
      }, 1000)
    },

    // 跳转协议
    gotoPage(type) {
      const url = {
        1: 'https://eq532q6q964.feishu.cn/docx/PXgidF4XWoEDqGxDyQScGsutnOP',
        2: 'https://eq532q6q964.feishu.cn/docx/P1GxdZVJaoQFVrxaYuDcvIBznPg'
      }[type]

      window.open(url, '_blank')
    },

    // 登录
    async submitLogin() {
      try {
        if (!this.readAgree) return this.$message.error('请勾选阅读协议')
        if (!this.phone.length || !/^1[3-9]\d{9}$/.test(this.phone)) {
          return this.$message.error('请输入正确的手机号')
        }
        if (!this.code) return this.$message.error('请输入验证码')
        if (this.isBindWx) {
          const res = await this.$axios.post('/v1/users/bindPhone', { phone: `+86${this.phone}`, code: this.code, openid: this.loginModalInfo.openid })
          localStorage.setItem('bread_front_readAgree', true)
          this.$store.commit('SET_USER', { phone: this.phone, ...res.data })
          this.$store.commit('SET_KEY', { key: 'showLoginModal', value: false })
          this.$store.commit('SET_KEY', {
            key: 'loginModalInfo',
            value: {
              needRegPhoneNumber: false,
              openid: ''
            }
          })
          this.$mixpanel.identify(res.data.id)
          this.$mixpanel.people.set({ $name: this.phone })
          this.$mixpanel.track('绑定微信成功', { phone: this.phone })
        } else {
          // const res = await this.$axios.post('/v1/users/login/sms/validation', { phone: `+86${this.phone}`, code: this.code })
          const res = await userLogin({
            code: this.code,
            phone: this.phone,
            type: 2
          })
          console.log('登录结果', res)
          localStorage.setItem('bread_front_readAgree', true)
          this.$store.commit('SET_USER', { phone: this.phone, ...res.data, twoToken: res.data.token, token: res.data.twoToken })
          this.$store.commit('SET_KEY', { key: 'showLoginModal', value: false })
          this.$mixpanel.identify(res.data.id)
          this.$mixpanel.people.set({ $name: this.phone })
          this.$mixpanel.track('登录注册', { phone: this.phone })
        }
        const redirect = decodeURIComponent(this.$route.query.redirect || '/')
        this.$router.push(redirect)
      } catch (error) {
        this.$message.error(error || '服务异常，请重试')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login__model {
  ::v-deep {
    .el-dialog {
      border-radius: 10px !important;
      width: calc(100% - 60px) !important;
      max-width: 561px !important;

      .el-dialog__header {
        padding: 0;
        .el-dialog__headerbtn {
          .el-dialog__close {
            color: #000;
          }
        }
      }

      .el-dialog__body {
        padding: 50px 0 0 0;
      }
    }
  }

  .el__outerLayer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;

    .el__he {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      .el__hard {
        width: 90px;
        flex-shrink: 0;
        border-radius: 7.5px;
        margin-bottom: 14px;
      }

      .el__breed {
        width: 50px;
        height: 16px;
        flex-shrink: 0;
      }
    }

    .cellPhone__number {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 317px;
      height: 49px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #e8f4ff;
      padding: 0 14px 0 25px;
      margin-bottom: 10px;

      .cellPhone__input {
        background: #e8f4ff;
        color: #333;
        font-family: PoetsenOne;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        width: 100%;
      }

      .cellPhone__vector {
        width: 18px;
        height: 18px;
        cursor: pointer;
        flex-shrink: 0;
      }

      .send__code {
        color: #1890ff;
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        flex-shrink: 0;
      }
    }

    .logIn__butt {
      width: 100%;
      max-width: 317px;
      height: 49px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #1890ff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-family: 'PingFang SC';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 20px;
      cursor: pointer;

      &.small__btn {
        height: 45px;
        font-size: 17px;
      }
    }

    .weChat__board {
      display: flex;
      align-items: center;
      margin-bottom: 34px;
      cursor: pointer;

      .weChat__Uname {
        color: #333;
        font-family: PoetsenOne;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        padding-left: 14px;
      }
    }
  }

  .microScan__Side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .microScan__return {
      width: 18px;
      height: 20px;
      flex-shrink: 0;
      position: absolute;
      top: 35px;
      left: 38px;
      cursor: pointer;
    }

    .el__he {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      .el__hard {
        width: 80px;
        flex-shrink: 0;
        border-radius: 7.5px;
        margin-bottom: 14px;
      }

      .el__breed {
        width: 50px;
        height: 16px;
        flex-shrink: 0;
      }
    }

    .qrCode__picture {
      width: 160px;
      height: 160px;
      overflow: hidden;
      flex-shrink: 0;
      background: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 14px;

      .qrCode__image {
        width: 150px;
        height: 150px;
        flex-shrink: 0;
      }
    }

    .open__beat {
      display: flex;
      align-items: center;
      color: #333;
      font-family: PoetsenOne;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      padding-bottom: 46px;

      .open__weCat {
        height: 17px;
        width: 17px;
        margin: 0 4px;
      }
    }
  }

  .read__part {
    display: flex;
    align-items: center;
    justify-content: center;

    .read__icon {
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      border-radius: 4px;
      background: #d9d9d9;
      cursor: pointer;
    }

    .read__text {
      width: 247px;
      height: 14px;
      margin-left: 6px;
    }

    .read__agree {
      display: flex;
      align-items: center;
      color: #333;
      font-family: PoetsenOne;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      padding-left: 6px;

      .el__bre {
        height: 10px;
        width: 40px;
        margin: 0 2px;
      }
    }
  }
}
</style>

<style lang="scss">
.qrCode__picture {
  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
