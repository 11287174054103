import axios from 'axios'
import store from '@/store'
import router from '@/router'

const serverBaseUrl = process.env.VUE_APP_PATH
// 文件上传URL
export const fileUploadUrl = `${serverBaseUrl}/v1/materials`

// 创建axios实例
const service = axios.create({
  baseURL: serverBaseUrl,
  withCredentials: true,
  timeout: 20 * 60 * 1000
})

// request拦截器
service.interceptors.request.use(config => {
  // config.headers['Content-Type'] = `application/json;charset=UTF-8`
  const token = store.state.userInfo.token
  config.headers['Authorization'] = `Bearer ${token}`
  return config
})

// response 拦截器
service.interceptors.response.use(
  res => {
    return new Promise((resolve, reject) => {
      if (res.status === 401) {
        store.commit('SET_USER', { token: '' })
        router.push('/app/index')
      } else if (Array.isArray(res.data) && res.status === 200) {
        resolve(res.data)
      } else if (+res.data.code === 200) {
        resolve(res.data)
      } else {
        reject(res.data.error)
      }
    })
  },
  (res) => {
    return new Promise((resolve, reject) => {
      console.log('请求异常', res)
      if (res.status === 401 || (res.response && res.response.status === 401)) {
        store.commit('SET_USER', { token: '' })
        router.push('/app/index')
      } else if (res.response) {
        reject(res.response.data.message)
      } else {
        reject(res)
      }
    })
  }
)

export default service
